const bingoCards = {
  1: { B: [14, 4, 10, 8, 12], I: [30, 28, 23, 16, 27], N: [41, 35, 0, 43, 39], G: [53, 57, 46, 48, 56], O: [74, 68, 75, 70, 66] },
  2: { B: [15, 13, 8, 1, 12], I: [26, 20, 19, 28, 24], N: [38, 42, 0, 33, 41], G: [59, 53, 60, 55, 51], O: [59, 53, 60, 55, 51] },
  3: { B: [11, 5, 4, 13, 9], I: [23, 27, 16, 18, 26], N: [44, 38, 0, 40, 36], G: [53, 47, 56, 55, 50], O: [69, 65, 63, 61, 74] },
  4: { B: [8, 12, 1, 3, 11], I: [29, 23, 30, 25, 21], N: [38, 32, 0, 40, 35], G: [54, 50, 48, 46, 59], O: [74, 70, 68, 72, 67] },
  5: { B: [14, 8, 15, 10, 6], I: [23, 17, 26, 25, 20], N: [39, 30, 0, 31, 44], G: [59, 55, 53, 57, 52], O: [63, 61, 71, 64, 68] },
  6: { B: [8, 2, 11, 10, 5], I: [24, 20, 18, 16, 29], N: [44, 40, 0, 42, 37], G: [48, 46, 56, 49, 53], O: [75, 69, 66, 71, 65] },
  7: { B: [9, 5, 3, 1, 14], I: [29, 25, 23, 27, 22], N: [33, 31, 0, 34, 38], G: [60, 54, 51, 56, 50], O: [70, 71, 67, 65, 61] },
  8: { B: [13, 4, 8, 12, 7], I: [18, 16, 26, 19, 23], N: [45, 39, 0, 41, 35], G: [55, 56, 52, 50, 46], O: [65, 69, 64, 61, 73] },
  9: { B: [3, 1, 11, 4, 8], I: [30, 24, 21, 26, 20], N: [40, 41, 0, 35, 31], G: [50, 54, 49, 46, 58], O: [73, 72, 71, 63, 62] },
  10: { B: [15, 9, 6, 11, 5], I: [25, 26, 22, 20, 16], N: [35, 39, 0, 31, 43], G: [58, 57, 56, 48, 47], O: [69, 68, 63, 66, 62] },
  11: { B: [10, 11, 7, 5, 1], I: [20, 24, 19, 16, 28], N: [43, 42, 0, 33, 32], G: [54, 53, 48, 51, 47], O: [72, 70, 64, 61, 67] },
  12: { B: [5, 9, 4, 1, 13], I: [28, 27, 26, 18, 17], N: [39, 38, 0, 36, 32], G: [57, 55, 49, 46, 52], O: [66, 64, 68, 63, 65] },
  13: { B: [13, 12, 11, 3, 2], I: [24, 23, 18, 21, 17], N: [42, 40, 0, 31, 37], G: [51, 49, 53, 48, 50], O: [67, 62, 64, 72, 74] },
  14: { B: [9, 8, 3, 6, 2], I: [27, 25, 19, 16, 22], N: [36, 34, 0, 33, 35], G: [52, 47, 49, 57, 59], O: [62, 64, 68, 66, 67] },
  15: { B: [12, 10, 4, 1, 7], I: [21, 19, 23, 18, 20], N: [37, 32, 0, 42, 44], G: [47, 49, 53, 51, 52], O: [63, 75, 73, 61, 68] },
  16: { B: [6, 4, 8, 3, 5], I: [22, 17, 19, 27, 29], N: [32, 34, 0, 36, 37], G: [48, 60, 58, 46, 53], O: [70, 65, 66, 62, 64] },
  17: { B: [7, 2, 4, 12, 14], I: [17, 19, 23, 21, 22], N: [33, 45, 0, 31, 38], G: [55, 50, 51, 47, 49], O: [69, 71, 74, 72, 67] },
  18: { B: [2, 4, 8, 6, 7], I: [18, 30, 28, 16, 23], N: [40, 35, 0, 32, 34], G: [54, 56, 59, 57, 52], O: [67, 75, 68, 66, 74] },
  19: { B: [3, 15, 13, 1, 8], I: [25, 20, 21, 17, 19], N: [39, 41, 0, 42, 37], G: [52, 60, 53, 51, 59], O: [73, 63, 62, 66, 75] },
  20: { B: [10, 5, 6, 2, 4], I: [24, 26, 29, 27, 22], N: [37, 45, 0, 36, 44], G: [58, 48, 47, 51, 60], O: [62, 70, 66, 64, 61] },
  21: { B: [9, 11, 14, 12, 7], I: [33, 30, 23, 21, 29], N: [43, 33, 0, 36, 45], G: [47, 55, 51, 49, 46], O: [67, 62, 73, 68, 66] },
  22: { B: [7, 15, 8, 6, 14], I: [28, 18, 17, 21, 30], N: [32, 40, 0, 34, 31], G: [52, 47, 58, 53, 51], O: [66, 69, 63, 75, 74] },
  23: { B: [13, 3, 2, 6, 15], I: [17, 25, 21, 19, 16], N: [37, 32, 0, 38, 36], G: [51, 54, 48, 60, 59], O: [67, 66, 63, 71, 70] },
  24: { B: [2, 10, 6, 4, 1], I: [22, 17, 28, 23, 21], N: [36, 39, 0, 45, 44], G: [52, 51, 48, 56, 55], O: [73, 72, 62, 74, 68] },
  25: { B: [7, 2, 13, 8, 6], I: [21, 24, 18, 30, 29], N: [37, 36, 0, 41, 40], G: [58, 57, 47, 59, 53], O: [73, 70, 66, 67, 75] },
  26: { B: [6, 9, 3, 15, 14], I: [22, 21, 18, 26, 25], N: [43, 42, 0, 44, 38], G: [58, 55, 51, 52, 60], O: [69, 61, 70, 64, 72] },
  27: { B: [7, 6, 3, 11, 10], I: [28, 27, 17, 29, 23], N: [43, 40, 0, 37, 45], G: [54, 46, 55, 49, 57], O: [69, 67, 65, 73, 75] },
  28: { B: [13, 12, 2, 14, 8], I: [28, 25, 21, 22, 30], N: [39, 31, 0, 34, 42], G: [54, 52, 50, 58, 60], O: [65, 72, 70, 71, 69] },
  29: { B: [13, 10, 6, 7, 15], I: [24, 16, 25, 19, 27], N: [39, 37, 0, 43, 45], G: [50, 57, 55, 56, 54], O: [73, 69, 61, 68, 74] },
  30: { B: [9, 1, 10, 4, 12], I: [24, 22, 20, 28, 30], N: [35, 42, 0, 41, 39], G: [58, 54, 46, 53, 59], O: [67, 72, 66, 62, 70] }
};

module.exports = {bingoCards}
