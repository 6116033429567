// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from './App';
import CardPage from './components/CardPage';
import { Buffer } from 'buffer';
import { process } from 'process';
import { useDrag } from "react-dnd";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
        <Route path="/:cardNumber" component={CardPage} />
      </Switch>
    </Router>
  </React.StrictMode>
);
