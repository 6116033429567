import React, { useEffect, useState, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Buffer } from 'buffer';
import { process } from 'process';
//import './BingoCard.css';

const App = () => {
  const [isAllowed, setIsAllowed] = useState(true);
  const [testPaymentLink, setTestPaymentLink] = useState(null);
  const [livePaymentLink, setLivePaymentLink] = useState(null);
  const qrRef = useRef(null);
  const [loading, setLoading] = useState(true);


  const [cardPrice, setCardPrice] = useState('50'); // Set default card price
  const prize = JSON.parse(cardPrice) * 30 / 100 * 60
  console.log('Prize: ', prize)
  const [winPrize, setWinPrize] = useState(prize); // Set default win prize
  console.log('Win Prize Dynamic', winPrize)

  const triggerEmailFunction = async (emailData) => {
  try {
    const response = await fetch('https://us-central1-gmailcloud-438107.cloudfunctions.net/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailData), // Send email data to the cloud function
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const result = await response.text();
    console.log('Function triggered successfully:', result);
  } catch (error) {
    console.error('Failed to trigger function:', error);
  }
};

useEffect(() => {
  // Example dynamic data
  const emailData = {
    to: 'botbingo.assistant@gmail.com',
    subject: 'Charlie Started Up.',
    dynamicContent: 'Charlie Started Up.',
  };

  // Trigger the function with dynamic data
  triggerEmailFunction(emailData);
}, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Hide the splash screen
    }, 2500); // 3000ms = 3 seconds

    return () => clearTimeout(timer); // Cleanup the timer when component unmounts
  }, []);

  useEffect(() => {
    const randomCardNumber = Math.floor(Math.random() * 30) + 1;
    //localStorage.randomCardNumber = randomCardNumber;
    localStorage.randomCardNumber = '1'

    const fetchProductId = async (apikey) => {
      try {
          const headers = {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': `Bearer ${apikey}`
          };

          const dataString = new URLSearchParams({
              name: 'Card #' + randomCardNumber.toString(),
              description: 'Bingo Card #' + randomCardNumber.toString() + ' will load after payment and the round starts within 5 seconds. Good Luck, winning the prize' + ' of '+ winPrize + '! 🍀'
          }).toString();

          const response = await fetch('https://api.stripe.com/v1/products', {
              method: 'POST',
              headers: headers,
              body: dataString
          });

          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          const data = await response.json();
          return data.id;
      } catch (error) {
          console.error('Error fetching product ID: ', error);
      }
    };

    const fetchPriceId = async (apikey, productId) => {
      try {
          const headers = new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': `Bearer ${apikey}`
          });

          const dataString = `unit_amount=5000&currency=eur&product=${productId}`;

          const response = await fetch('https://api.stripe.com/v1/prices', {
              method: 'POST',
              headers: headers,
              body: dataString
          });

          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          const responseBody = await response.json();
          return responseBody.id;
      } catch (e) {
          console.error('Error fetching price ID: ', e);
      }
    };

    const fetchPaymentLink = async (apikey, priceId, environment) => {
      try {
          const headers = new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': `Bearer ${apikey}`
          });

          const dataString = `line_items[0][price]=${priceId}&line_items[0][quantity]=1&after_completion[type]=redirect&after_completion[redirect][url]=https://jibodev.eu/${localStorage.randomCardNumber}?startGame=true&metadata[order_id]=6735`;

          const response = await fetch('https://api.stripe.com/v1/payment_links', {
              method: 'POST',
              headers: headers,
              body: dataString
          });

          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          const responseBody = await response.json();
          if (environment === 'test') {
              setTestPaymentLink(responseBody.url);
          } else {
              setLivePaymentLink(responseBody.url);
          }
      } catch (e) {
          console.error('Error fetching payment link: ', e);
      }
    };

    const initializeStripeLinks = async () => {
      const testApiKey = 'sk_test_51JQ5y7LBjT70MpWNartHt9OCG9s8kjfoGci9aclIl7VHrvDA3gTrxEoLSpdtFUbFJQkIVonhAlyYSn6HRpCXasJn00oWDFFTyi';
      const liveApiKey = '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';

      // Test Environment
      const testProductId = await fetchProductId(testApiKey);
      const testPriceId = await fetchPriceId(testApiKey, testProductId);
      await fetchPaymentLink(testApiKey, testPriceId, 'test');

      // Live Environment
      const liveProductId = await fetchProductId(liveApiKey);
      const livePriceId = await fetchPriceId(liveApiKey, liveProductId);
      await fetchPaymentLink(liveApiKey, livePriceId, 'live');
    };

    initializeStripeLinks();
  }, []);

  return (
    <div>
   {loading ? (
     // Splash Screen (Just the background image)
     <div className="splash-screen">
       {/* This div will only show the background image */}
     </div>
   ) : (
     // The rest of your page content goes here
     <div style={{
       textAlign: 'center', marginTop: '50px', padding: '20px',
       backgroundColor: 'rgba(240, 230, 236, 0.7)', borderRadius: '10px',
       boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
     }}>
         <h1>CHARLIE'S DRAG BINGO!</h1>
         <p>⭐⭐ WIN FABULOUS PRIZES ⭐⭐</p>
         <h2>WIN PRIZE: €{winPrize},-</h2>
         <h2>CARD PRICE: €{cardPrice}.-</h2>
       <div className="App">
         <div ref={qrRef} />
       </div>
       {isAllowed ? (
         <>
           <div id="stripe-buy-button-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
             {testPaymentLink ? (
               <img
                 src="https://jibodev.eu/buybuttontest.png"
                 alt="Buy Button for Test Network"
                 style={{ cursor: 'pointer', height: '85px', width: '255px' }}
                 onClick={() => window.location.href = testPaymentLink }
               />
             ) : (
               <p>Loading test payment link...</p>
             )}
             {livePaymentLink ? (
               <img
                 src="https://jibodev.eu/buybuttonlive.png"
                 alt="Buy Button for Live Network"
                 style={{ cursor: 'pointer', height: '100px', width: '300px' }}
                 onClick={() => window.location.href = livePaymentLink }
               />
             ) : (
               <p>Loading live payment link...</p>
             )}
           </div>
         </>
       ) : (
         <p>Sorry, this game is not available in your country.</p>
       )}

       <p>Your card auto-loads after payment and the <b>DRAG BINGO</b> starts after 5 seconds.</p>
       <p><i>You win with a full card only (e.g.</i> <b>DRAG BINGO</b>). <i>More winners, divide the prize.</i></p>
       <p><b>Maximum 1 (of 30 cards) per round.</b></p>
     </div>
   )}
 </div>

  );
};

export default App;
