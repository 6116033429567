import React, { useState, useEffect, useCallback  } from 'react';
import './BingoCard.css';
import { useLocation } from 'react-router-dom';
import { bingoCards } from '../cards';
import confetti from 'canvas-confetti';
import { AiOutlineSound, AiOutlineMuted } from 'react-icons/ai';
import { Alchemy, Network, Wallet, Utils } from "alchemy-sdk";
import { GoogleSpreadsheet } from "google-spreadsheet";
import dotenv from "dotenv";
import swal from 'sweetalert';


const BingoCard = ({ card, drawnNumbers, cardNumber }) => {
  const [pitch, setPitch] = useState(1); // Default is 1 (normal pitch)
  const [rate, setRate] = useState(1);  // Default is 1 (normal rate)
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [markedNumbers, setMarkedNumbers] = useState([]);
  const [bingoResultMessage, setBingoResultMessage] = useState('');
  const [bingoMessage, setBingoMessage] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const [autoScratch, setAutoScratch] = useState(true);
  const [gameStarted, setGameStarted] = useState(false);
  const [lastNumbers, setLastNumbers] = useState([]);
  const [intervalId, setIntervalId] = useState(null);
  const [board, setBoard] = useState({}); // Track card state (marked numbers)
  const [gamePaused, setGamePaused] = useState(true); // Game is paused initially
  const location = useLocation();
  const [isBingo, setIsBingo] = useState(false);
  const [winningCards, setWinningCards] = useState([]);
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [loading, setLoading] = useState(true);


  const triggerEmailFunction = async (emailData) => {
  try {
    const response = await fetch('https://us-central1-gmailcloud-438107.cloudfunctions.net/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailData), // Send email data to the cloud function
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const result = await response.text();
    console.log('Function triggered successfully:', result);
  } catch (error) {
    console.error('Failed to trigger function:', error);
  }
};

useEffect(() => {
  // Example dynamic data
  const emailData = {
    to: 'botbingo.assistant@gmail.com',
    subject: 'New Game Started',
    dynamicContent: `New Game For CARD NUMBER #${cardNumber}`,
  };

  // Trigger the function with dynamic data
  triggerEmailFunction(emailData);
}, []);


  // Detect `startGame=true` in the URL query parameters and auto-start the game
  useEffect(() => {
      const storedWinningCards = JSON.parse(localStorage.getItem('winningCards')) || [];
        setWinningCards(storedWinningCards); // Set the winning cards in state


      // Check if the user is allowed to play based on localStorage
      const isAllowed = localStorage.getItem('isAllowed');

      if (isAllowed === 'false') {
        alert('Sorry, purchases and gameplay are not allowed from your country.');
        return; // Block game start
      }

      // Continue with auto-start logic
      const searchParams = new URLSearchParams(window.location.search);
      const shouldStartGame = searchParams.get('startGame');
      if (shouldStartGame === 'true') {
        setTimeout(() => {
          console.log('RemoveLoc & Start')

          localStorage.removeItem('updatedNumbersLength');
          localStorage.removeItem('totalCalls');
          startGame();
        }, 7000); // 5-second delay
      }
    }, []);

    useEffect(() => {
       const fetchVoices = () => {
         const synthVoices = window.speechSynthesis.getVoices();
         setVoices(synthVoices);
         if (synthVoices.length > 0) {
           setSelectedVoice(synthVoices[0]); // Set default voice
         }
       };

       fetchVoices();
       window.speechSynthesis.onvoiceschanged = fetchVoices;
     }, []);

    useEffect(() => {
        if (gameStarted) {
          const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // Modern browsers require returnValue to be set.
          };

          window.addEventListener('beforeunload', handleBeforeUnload);

          return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
          };
        }
      }, [gameStarted]);

      useEffect(() => {
        const timer = setTimeout(() => {
          setLoading(false); // Hide the splash screen
        }, 3000); // 3000ms = 3 seconds

        return () => clearTimeout(timer); // Cleanup the timer when component unmounts
      }, []);





    const startGame = () => {
      console.log(`YOUR CARD NUMBER = #${cardNumber}`)
    setGameStarted(true); // Set the game as started
    setLastNumbers([]); // Reset the last drawn numbers
    drawNumber(); // Draw the first number immediately
    const id = setInterval(() => {
        // Check gameStarted status before drawing the next number
        setGameStarted((currentGameStarted) => {
            if (currentGameStarted) {
                drawNumber(); // Only draw if the game is still running
            } else {
                clearInterval(id); // Clear interval if the game is stopped
            }
            return currentGameStarted;
        });
    }, 15000); // 15-second interval between draws
    setIntervalId(id); // Save the interval ID to clear it later
};

const stopGame = () => {
    console.log("Stopping game...");
    setIsBingo(true)

};

  const toggleGame = () => {
    if (gameStarted) {
      stopGame();
    } else {
      startGame();
    }
  };



  const toggleAutoScratch = () => {
  setAutoScratch(prev => !prev);
  };

  useEffect(() => {
  if (autoScratch) {
    const newMarkedNumbers = lastNumbers.filter(number =>
      Object.values(card).flat().includes(number)
    );
    setMarkedNumbers(newMarkedNumbers);
  }
  }, [lastNumbers, card, autoScratch]); // <-- Dependency array was correctly set here


  const getRandomIntro = () => {
/*
      const intros = [
        "The next number is. Number,  ",
        "Here comes. Number, ",
        "Let's see what we have. , number,",
        "This is the next number. Number,",
        "Next up is. Number,",
        "Listen carefully to. Number,"
      ];
*/
      const intros = [
        "Darlings, the next fabulous number is, ",
        "Hold onto your wigs! Here comes number, ",
        "Work it! Let's see what we have, number, ",
        "Well honey, this is the next stunning number, ",
        "Get ready to slay! Next up is number, ",
        "Listen up, my queens! The magic number is, ",
        "Honey, get those daubers ready! Number, ",
        "Feast your eyes on this, number, ",
        "Sparkle and shine, it's time for number, ",
        "Glam it up! The next star is, ",
        "Fierce and fantastic, here comes number, "
      ];
      return intros[Math.floor(Math.random() * intros.length)];
    };

    const speakNumber = useCallback((number) => {
      console.log('Speakining Number: ', number)
      console.log('Sound On: ', isSoundOn)
       if (isSoundOn) {
         const letter = getLetterForNumber(number);
         const intro = getRandomIntro();
         setIsSoundOn(true)
         const utterance = new SpeechSynthesisUtterance(`${intro} ${letter}-${number}`);
         const utterance1 = new SpeechSynthesisUtterance(`${letter}-${number}`);
         utterance.lang = 'en-US';
         utterance.pitch = 1.6;  // Higher pitch
         utterance.rate = 0.8;   // Slower rate
         utterance.volume = 1.0; // Full volume
         utterance1.lang = 'en-US';
         utterance1.pitch = 1.6;  // Higher pitch
         utterance1.rate = 0.8;   // Slower rate
         utterance1.volume = 1.0; // Full volume
         if (selectedVoice) {
           utterance.voice = selectedVoice;
           console.log(`Using voice: ${selectedVoice.name} with lang: ${selectedVoice.lang}`);
         } else {
           console.log('No voice selected');
         }
         // Adding debug log to ensure speakNumber is being called
         console.log(`Speaking: ${intro} ${letter}-${number}`);
         window.speechSynthesis.speak(utterance);
         setTimeout(() => {
           window.speechSynthesis.speak(utterance1);
         }, 2000); // 5-second delay
         setTimeout(() => {
           setIsSoundOn(false)
           }, 3000);
       }
     }, [isSoundOn, selectedVoice]);


  // Function to draw a number and check for Bingo after each draw
  const drawNumber = () => {
    //console.log('In DrawNr - LastNumLength', localStorage.getItem('updatedNumbersLength')); // Check current length
    //console.log('GetItemLoc:', localStorage.getItem('updatedNumbersLength'));
    const getItemLoc = localStorage.getItem('updatedNumbersLength');
    if (getItemLoc >= 75) {
        console.log('All 75 numbers have been drawn, stopping the game.');
        stopGame(); // Stop game if all numbers have been drawn
        return;
    }
    else{
      console.log('Continue due to < 75')
    }

    let newNumber;
    let checkCount = 0; // Count how many checks happen inside the while loop

    setLastNumbers((prevNumbers) => {
        const updatedNumbers1 = [...prevNumbers]; // Get the current state of last numbers
        //console.log('Updated Numbers1: ', updatedNumbers);
        //console.log('Calling CACFB')

        do {
            newNumber = Math.floor(Math.random() * 75) + 1; // Generate a random number between 1 and 75
            checkCount++;
          //  console.log(`Check ${checkCount}: Trying new number ${newNumber}. Already drawn numbers: `, updatedNumbers);
        } while (updatedNumbers1.includes(newNumber)); // Repeat until a unique number is found

        //console.log(`Final new number after ${checkCount} checks: ${newNumber}`);
        updatedNumbers1.push(newNumber); // Add the new number to the updatedNumbers array
        const updatedNumbers = [14, 4, 10, 8, 12, 30, 28, 23, 16, 27, 41, 35, 43, 39, 53, 57, 46, 48, 56, 74, 68, 75, 70, 66]
        console.log('Drawn Numbers: ', updatedNumbers);
        setTimeout(() => {
          // Example of using the updatedNumbers array in the game logic
        //  checkAllCardsForBingo(mockUpdatedNumbers);
        checkBingo(updatedNumbers);

        }, 1000); // 5-second delay
        console.log('Sound On = ', isSoundOn)
        if(isSoundOn){
          speakNumber(newNumber); // Speak the drawn number
        }

        localStorage.setItem('updatedNumbers', JSON.stringify(updatedNumbers)); // Store in localStorage
        localStorage.setItem('updatedNumbersLength', updatedNumbers.length); // Store in localStorage
        console.log('Drawn Number:', newNumber);
        return updatedNumbers; // Return the updated list to update state
    });
  };

  // Function to mimic a guaranteed Bingo card for testing
  const createGuaranteedBingoCard = () => {
      return {
          B: [1, 2, 3, 4, 5],
          I: [16, 17, 18, 19, 20],
          N: [31, 32, 33, 34, 35],
          G: [46, 47, 48, 49, 50],
          O: [61, 62, 63, 64, 65],
      };
  };


//  const newCard = createGuaranteedBingoCard(); // or however you generate a new card
//bingoCards[Object.keys(bingoCards).length + 1] = newCard;
//  console.log('New card', newCard)
  // Mock numbers to ensure a bingo card is matched in the check
//  const mockUpdatedNumbers = [14, 8, 15, 10, 6, 23, 17, 26, 25, 20, 39, 30, 0, 31, 44, 59, 55, 53, 57, 52, 63, 61, 71, 64, 68];

  // Test with the mock numbers when calling checkAllCardsForBingo
  //setTimeout(() => {
      // Pass mockUpdatedNumbers to test the bingo matching
    //  checkAllCardsForBingo(mockUpdatedNumbers);
  //}, 2000);  // Adjusted delay if needed


  const checkBingo = (updatedNumbers) => {
    console.log('checking card(s)', cardNumber)
try {
const card001 = [14, 4, 10, 8, 12, 30, 28, 23, 16, 27, 41, 35, 43, 39, 53, 57, 46, 48, 56, 74, 68, 75, 70, 66]
const card002 = [15, 13, 8, 1, 12, 26, 20, 19, 28, 24, 38, 42, 33, 41, 59, 53, 60, 55, 51, 68, 62, 71, 70, 65]
const card003 = [11, 5, 4, 13, 9, 23, 27, 16, 18, 26, 44, 38, 40, 36, 53, 47, 56, 55, 50, 69, 65, 63, 61, 74]
const card004 = [8, 12, 1, 3, 11, 29, 23, 30, 25, 21, 38, 32, 40, 35, 54, 50, 48, 46, 59, 74, 70, 68, 72, 67]
const card005 = [14, 8, 15, 10, 6, 23, 17, 26, 25, 20, 39, 30, 31, 44, 59, 55, 53, 57, 52, 63, 61, 71, 64, 68]
const card006 = [8, 2, 11, 10, 5, 24, 20, 18, 16, 29, 44, 40, 42, 37, 48, 46, 56, 49, 53, 75, 69, 66, 71, 65]
const card007 = [9, 5, 3, 1, 14, 29, 25, 23, 27, 22, 33, 31, 34, 38, 60, 54, 51, 56, 50, 70, 71, 67, 65, 61]
const card008 = [13, 4, 8, 12, 7, 18, 16, 26, 19, 23, 45, 39, 41, 35, 55, 56, 52, 50, 46, 65, 69, 64, 61, 73]
const card009 = [3, 1, 11, 4, 8, 30, 24, 21, 26, 20, 40, 41, 35, 31, 50, 54, 49, 46, 58, 73, 72, 71, 63, 62]
const card010 = [15, 9, 6, 11, 5, 25, 26, 22, 20, 16, 35, 39, 31, 43, 58, 57, 56, 48, 47, 69, 68, 63, 66, 62]
const card011 = [10, 11, 7, 5, 1, 20, 24, 19, 16, 28, 43, 42, 33, 32, 54, 53, 48, 51, 47, 72, 70, 64, 61, 67]
const card012 = [5, 9, 4, 1, 13, 28, 27, 26, 18, 17, 39, 38, 36, 32, 57, 55, 49, 46, 52, 66, 64, 68, 63, 65]
const card013 = [13, 12, 11, 3, 2, 24, 23, 18, 21, 17, 42, 40, 31, 37, 51, 49, 53, 48, 50, 67, 62, 64, 72, 74]
const card014 = [9, 8, 3, 6, 2, 27, 25, 19, 16, 22, 36, 34, 33, 35, 52, 47, 49, 57, 59, 62, 64, 68, 66, 67]
const card015 = [12, 10, 4, 1, 7, 21, 19, 23, 18, 20, 37, 32, 42, 44, 47, 49, 53, 51, 52, 63, 75, 73, 61, 68]
const card016 = [6, 4, 8, 3, 5, 22, 17, 19, 27, 29, 32, 34, 36, 37, 48, 60, 58, 46, 53, 70, 65, 66, 62, 64]
const card017 = [7, 2, 4, 12, 14, 17, 19, 23, 21, 22, 33, 45, 31, 38, 55, 50, 51, 47, 49, 69, 71, 74, 72, 67]
const card018 = [2, 4, 8, 6, 7, 18, 30, 28, 16, 23, 40, 35, 32, 34, 54, 56, 59, 57, 52, 67, 75, 68, 66, 74]
const card019 = [3, 15, 13, 1, 8, 25, 20, 21, 17, 19, 39, 41, 42, 37, 52, 60, 53, 51, 59, 73, 63, 62, 66, 75]
const card020 = [10, 5, 6, 2, 4, 24, 26, 29, 27, 22, 37, 45, 36, 44, 58, 48, 47, 51, 60, 62, 70, 66, 64, 61]
const card021 = [9, 11, 14, 12, 7, 33, 30, 23, 21, 29, 43, 33, 36, 45, 47, 55, 51, 49, 46, 67, 62, 73, 68, 66]
const card022 = [7, 15, 8, 6, 14, 28, 18, 17, 21, 30, 32, 40, 34, 31, 52, 47, 58, 53, 51, 66, 69, 63, 75, 74]
const card023 = [13, 3, 2, 6, 15, 17, 25, 21, 19, 16, 37, 32, 38, 36, 51, 54, 48, 60, 59, 67, 66, 63, 71, 70]
const card024 = [2, 10, 6, 4, 1, 22, 17, 28, 23, 21, 36, 39, 45, 44, 52, 51, 48, 56, 55, 73, 72, 62, 74, 68]
const card025 = [7, 2, 13, 8, 6, 21, 24, 18, 30, 29, 37, 36, 41, 40, 58, 57, 47, 59, 53, 73, 70, 66, 67, 75]
const card026 = [6, 9, 3, 15, 14, 22, 21, 18, 26, 25, 43, 42, 44, 38, 58, 55, 51, 52, 60, 69, 61, 70, 64, 72]
const card027 = [7, 6, 3, 11, 10, 28, 27, 17, 29, 23, 43, 40, 37, 45, 54, 46, 55, 49, 57, 69, 67, 65, 73, 75]
const card028 = [13, 12, 2, 14, 8, 28, 25, 21, 22, 30, 39, 31, 34, 42, 54, 52, 50, 58, 60, 65, 72, 70, 71, 69]
const card029 = [13, 10, 6, 7, 15, 24, 16, 25, 19, 27, 39, 37, 43, 45, 50, 57, 55, 56, 54, 73, 69, 61, 68, 74]
const card030 = [9, 1, 10, 4, 12, 24, 22, 20, 28, 30, 35, 42, 41, 39, 58, 54, 46, 53, 59, 67, 72, 66, 62, 70]
const cardCalllist = [9, 1, 10, 4, 12, 24, 22, 20, 28, 30, 35, 42, 41, 39, 58, 54, 46, 53, 59, 67, 72, 66, 62, 70]


const cards = { card001, card002, card003, card004, card005, card006, card007, card008, card009, card010, card011, card012, card013, card014, card015, card016, card017, card018, card019, card020, card021, card022, card023, card024, card025, card026, card027, card028, card029, card030 };
  const callList = cardCalllist;
  const checkDrawn = updatedNumbers;
//  const containsAll = cards[cardVarName].every(element => {
//    return checkDrawn.includes(element);
//  });
  const containsAll1 = card001.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll2 = card002.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll3 = card003.every(element => {
    return checkDrawn.includes(element);
  });
  //const containsAll4 = true
  const containsAll4 = card004.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll5 = card005.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll6 = card006.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll7 = card007.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll8 = card008.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll9 = card009.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll10 = card010.every(element => {
    return checkDrawn.includes(element);
  });


  const containsAll11 = card011.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll12 = card012.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll13 = card013.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll14 = card014.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll15 = card015.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll16 = card016.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll17 = card017.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll18 = card018.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll19 = card019.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll20 = card020.every(element => {
    return checkDrawn.includes(element);
  });


  const containsAll21 = card021.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll22 = card022.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll23 = card023.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll24 = card024.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll25 = card025.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll26 = card026.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll27 = card027.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll28 = card028.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll29 = card029.every(element => {
    return checkDrawn.includes(element);
  });
  const containsAll30 = card030.every(element => {
    return checkDrawn.includes(element);
  });

  //  console.log(containsAll); // 👉️ true
      //console.log('C1',containsAll1); // 👉️ true
  		localStorage.ca1 = containsAll1
      //console.log('C2',containsAll2); // 👉️ true
      localStorage.ca2 = containsAll2
      //console.log('C3',containsAll3); // 👉️ true
      localStorage.ca3 = containsAll3
      //console.log('C4',containsAll4); // 👉️ true
      localStorage.ca4 = containsAll4
      //console.log('C5',containsAll5); // 👉️ true
      localStorage.ca5 = containsAll5
      //console.log('C6',containsAll6); // 👉️ true
      localStorage.ca6 = containsAll6
      //console.log('C7',containsAll7); // 👉️ true
      localStorage.ca7 = containsAll7
      //console.log('C8',containsAll8); // 👉️ true
      localStorage.ca8 = containsAll8
      //console.log('C9',containsAll9); // 👉️ true
      localStorage.ca9 = containsAll9
      //console.log('C10',containsAll10); // 👉️ true
      localStorage.ca10 = containsAll10
      //console.log('C11',containsAll11); // 👉️ true
      localStorage.ca11 = containsAll11
      //console.log('C12',containsAll12); // 👉️ true
      localStorage.ca12 = containsAll12
      //console.log('C13',containsAll13); // 👉️ true
      localStorage.ca13 = containsAll13
      //console.log('C14',containsAll14); // 👉️ true
      localStorage.ca14 = containsAll14
      //console.log('C15',containsAll15); // 👉️ true
      localStorage.ca15 = containsAll15
      //console.log('C16',containsAll16); // 👉️ true
      localStorage.ca16 = containsAll16
      //console.log('C17',containsAll17); // 👉️ true
      localStorage.ca17 = containsAll17
      //console.log('C18',containsAll18); // 👉️ true
      localStorage.ca18 = containsAll18
      //console.log('C19',containsAll19); // 👉️ true
      localStorage.ca19 = containsAll19
      //console.log('C20',containsAll20); // 👉️ true
      localStorage.ca20 = containsAll20
      //console.log('C21',containsAll21); // 👉️ true
      localStorage.ca21 = containsAll21
      //console.log('C22',containsAll22); // 👉️ true
      localStorage.ca22 = containsAll22
      //console.log('C23',containsAll23); // 👉️ true
      localStorage.ca23 = containsAll23
      //console.log('C24',containsAll24); // 👉️ true
      localStorage.ca24 = containsAll24
      //console.log('C25',containsAll25); // 👉️ true
      localStorage.ca25 = containsAll25
      //console.log('C26',containsAll26); // 👉️ true
      localStorage.ca26 = containsAll26
      //console.log('C27',containsAll27); // 👉️ true
      localStorage.ca27 = containsAll27
      //console.log('C28',containsAll28); // 👉️ true
      localStorage.ca28 = containsAll28
      //console.log('C29',containsAll29); // 👉️ true
      localStorage.ca29 = containsAll29
      //console.log('C30',containsAll30); // 👉️ true
      localStorage.ca30 = containsAll30

    if (containsAll1 == true || containsAll2 == true || containsAll3 == true || containsAll4 == true || containsAll5 == true || containsAll6 == true || containsAll7 == true || containsAll8 == true || containsAll9 == true || containsAll10 == true
    || containsAll11 == true || containsAll12 == true || containsAll13 == true || containsAll14 == true || containsAll15 == true || containsAll16 == true || containsAll17 == true || containsAll18 == true || containsAll19 == true || containsAll20 == true
    || containsAll21 == true || containsAll22 == true || containsAll23 == true || containsAll24 == true || containsAll25 == true || containsAll26 == true || containsAll27 == true|| containsAll28 == true || containsAll29 == true
    || containsAll30 == true){
        console.log('BINGO!');
        clearInterval(intervalId); // Clear the interval immediately
        setIntervalId(null); // Reset the interval ID to null
        setGameStarted(false); // Mark the game as stopped
        stopGame();
        // Call checkWinningCards after 3 seconds
  setTimeout(() => {
    //goSentWinner();
      setTimeout(() => {
        checkWinningCards();
      }, 3000);
  }, 3000);
      }
      else{
        console.log('no bingo')
      }


} catch (error) {
    console.error(`Error during bingo card checking`);
}
  //  console.log(`Bingo Check for this card: ${isBingo}`);
    return;  // Return true if all columns have numbers in updatedNumbers
};

const checkBingoPlayer = (updatedNumbers) => {
  console.log('checking card(s)', cardNumber)
try {
const card001 = [14, 4, 10, 8, 12, 30, 28, 23, 16, 27, 41, 35, 43, 39, 53, 57, 46, 48, 56, 74, 68, 75, 70, 66]
const card002 = [15, 13, 8, 1, 12, 26, 20, 19, 28, 24, 38, 42, 33, 41, 59, 53, 60, 55, 51, 68, 62, 71, 70, 65]
const card003 = [11, 5, 4, 13, 9, 23, 27, 16, 18, 26, 44, 38, 40, 36, 53, 47, 56, 55, 50, 69, 65, 63, 61, 74]
const card004 = [8, 12, 1, 3, 11, 29, 23, 30, 25, 21, 38, 32, 40, 35, 54, 50, 48, 46, 59, 74, 70, 68, 72, 67]
const card005 = [14, 8, 15, 10, 6, 23, 17, 26, 25, 20, 39, 30, 31, 44, 59, 55, 53, 57, 52, 63, 61, 71, 64, 68]
const card006 = [8, 2, 11, 10, 5, 24, 20, 18, 16, 29, 44, 40, 42, 37, 48, 46, 56, 49, 53, 75, 69, 66, 71, 65]
const card007 = [9, 5, 3, 1, 14, 29, 25, 23, 27, 22, 33, 31, 34, 38, 60, 54, 51, 56, 50, 70, 71, 67, 65, 61]
const card008 = [13, 4, 8, 12, 7, 18, 16, 26, 19, 23, 45, 39, 41, 35, 55, 56, 52, 50, 46, 65, 69, 64, 61, 73]
const card009 = [3, 1, 11, 4, 8, 30, 24, 21, 26, 20, 40, 41, 35, 31, 50, 54, 49, 46, 58, 73, 72, 71, 63, 62]
const card010 = [15, 9, 6, 11, 5, 25, 26, 22, 20, 16, 35, 39, 31, 43, 58, 57, 56, 48, 47, 69, 68, 63, 66, 62]
const card011 = [10, 11, 7, 5, 1, 20, 24, 19, 16, 28, 43, 42, 33, 32, 54, 53, 48, 51, 47, 72, 70, 64, 61, 67]
const card012 = [5, 9, 4, 1, 13, 28, 27, 26, 18, 17, 39, 38, 36, 32, 57, 55, 49, 46, 52, 66, 64, 68, 63, 65]
const card013 = [13, 12, 11, 3, 2, 24, 23, 18, 21, 17, 42, 40, 31, 37, 51, 49, 53, 48, 50, 67, 62, 64, 72, 74]
const card014 = [9, 8, 3, 6, 2, 27, 25, 19, 16, 22, 36, 34, 33, 35, 52, 47, 49, 57, 59, 62, 64, 68, 66, 67]
const card015 = [12, 10, 4, 1, 7, 21, 19, 23, 18, 20, 37, 32, 42, 44, 47, 49, 53, 51, 52, 63, 75, 73, 61, 68]
const card016 = [6, 4, 8, 3, 5, 22, 17, 19, 27, 29, 32, 34, 36, 37, 48, 60, 58, 46, 53, 70, 65, 66, 62, 64]
const card017 = [7, 2, 4, 12, 14, 17, 19, 23, 21, 22, 33, 45, 31, 38, 55, 50, 51, 47, 49, 69, 71, 74, 72, 67]
const card018 = [2, 4, 8, 6, 7, 18, 30, 28, 16, 23, 40, 35, 32, 34, 54, 56, 59, 57, 52, 67, 75, 68, 66, 74]
const card019 = [3, 15, 13, 1, 8, 25, 20, 21, 17, 19, 39, 41, 42, 37, 52, 60, 53, 51, 59, 73, 63, 62, 66, 75]
const card020 = [10, 5, 6, 2, 4, 24, 26, 29, 27, 22, 37, 45, 36, 44, 58, 48, 47, 51, 60, 62, 70, 66, 64, 61]
const card021 = [9, 11, 14, 12, 7, 33, 30, 23, 21, 29, 43, 33, 36, 45, 47, 55, 51, 49, 46, 67, 62, 73, 68, 66]
const card022 = [7, 15, 8, 6, 14, 28, 18, 17, 21, 30, 32, 40, 34, 31, 52, 47, 58, 53, 51, 66, 69, 63, 75, 74]
const card023 = [13, 3, 2, 6, 15, 17, 25, 21, 19, 16, 37, 32, 38, 36, 51, 54, 48, 60, 59, 67, 66, 63, 71, 70]
const card024 = [2, 10, 6, 4, 1, 22, 17, 28, 23, 21, 36, 39, 45, 44, 52, 51, 48, 56, 55, 73, 72, 62, 74, 68]
const card025 = [7, 2, 13, 8, 6, 21, 24, 18, 30, 29, 37, 36, 41, 40, 58, 57, 47, 59, 53, 73, 70, 66, 67, 75]
const card026 = [6, 9, 3, 15, 14, 22, 21, 18, 26, 25, 43, 42, 44, 38, 58, 55, 51, 52, 60, 69, 61, 70, 64, 72]
const card027 = [7, 6, 3, 11, 10, 28, 27, 17, 29, 23, 43, 40, 37, 45, 54, 46, 55, 49, 57, 69, 67, 65, 73, 75]
const card028 = [13, 12, 2, 14, 8, 28, 25, 21, 22, 30, 39, 31, 34, 42, 54, 52, 50, 58, 60, 65, 72, 70, 71, 69]
const card029 = [13, 10, 6, 7, 15, 24, 16, 25, 19, 27, 39, 37, 43, 45, 50, 57, 55, 56, 54, 73, 69, 61, 68, 74]
const card030 = [9, 1, 10, 4, 12, 24, 22, 20, 28, 30, 35, 42, 41, 39, 58, 54, 46, 53, 59, 67, 72, 66, 62, 70]
const cardCalllist = [9, 1, 10, 4, 12, 24, 22, 20, 28, 30, 35, 42, 41, 39, 58, 54, 46, 53, 59, 67, 72, 66, 62, 70]


const cards = { card001, card002, card003, card004, card005, card006, card007, card008, card009, card010, card011, card012, card013, card014, card015, card016, card017, card018, card019, card020, card021, card022, card023, card024, card025, card026, card027, card028, card029, card030 };
const callList = cardCalllist;
const checkDrawn = updatedNumbers;
//  const containsAll = cards[cardVarName].every(element => {
//    return checkDrawn.includes(element);
//  });
const containsAll1 = card001.every(element => {
  return checkDrawn.includes(element);
});
const containsAll2 = card002.every(element => {
  return checkDrawn.includes(element);
});
const containsAll3 = card003.every(element => {
  return checkDrawn.includes(element);
});
//const containsAll4 = true
const containsAll4 = card004.every(element => {
  return checkDrawn.includes(element);
});
const containsAll5 = card005.every(element => {
  return checkDrawn.includes(element);
});
const containsAll6 = card006.every(element => {
  return checkDrawn.includes(element);
});
const containsAll7 = card007.every(element => {
  return checkDrawn.includes(element);
});
const containsAll8 = card008.every(element => {
  return checkDrawn.includes(element);
});
const containsAll9 = card009.every(element => {
  return checkDrawn.includes(element);
});
const containsAll10 = card010.every(element => {
  return checkDrawn.includes(element);
});


const containsAll11 = card011.every(element => {
  return checkDrawn.includes(element);
});
const containsAll12 = card012.every(element => {
  return checkDrawn.includes(element);
});
const containsAll13 = card013.every(element => {
  return checkDrawn.includes(element);
});
const containsAll14 = card014.every(element => {
  return checkDrawn.includes(element);
});
const containsAll15 = card015.every(element => {
  return checkDrawn.includes(element);
});
const containsAll16 = card016.every(element => {
  return checkDrawn.includes(element);
});
const containsAll17 = card017.every(element => {
  return checkDrawn.includes(element);
});
const containsAll18 = card018.every(element => {
  return checkDrawn.includes(element);
});
const containsAll19 = card019.every(element => {
  return checkDrawn.includes(element);
});
const containsAll20 = card020.every(element => {
  return checkDrawn.includes(element);
});


const containsAll21 = card021.every(element => {
  return checkDrawn.includes(element);
});
const containsAll22 = card022.every(element => {
  return checkDrawn.includes(element);
});
const containsAll23 = card023.every(element => {
  return checkDrawn.includes(element);
});
const containsAll24 = card024.every(element => {
  return checkDrawn.includes(element);
});
const containsAll25 = card025.every(element => {
  return checkDrawn.includes(element);
});
const containsAll26 = card026.every(element => {
  return checkDrawn.includes(element);
});
const containsAll27 = card027.every(element => {
  return checkDrawn.includes(element);
});
const containsAll28 = card028.every(element => {
  return checkDrawn.includes(element);
});
const containsAll29 = card029.every(element => {
  return checkDrawn.includes(element);
});
const containsAll30 = card030.every(element => {
  return checkDrawn.includes(element);
});

//  console.log(containsAll); // 👉️ true
    //console.log('C1',containsAll1); // 👉️ true
    localStorage.ca1 = containsAll1
    //localStorage.ca1 = true
    //console.log('C2',containsAll2); // 👉️ true
    localStorage.ca2 = containsAll2
    //console.log('C3',containsAll3); // 👉️ true
    localStorage.ca3 = containsAll3
    //console.log('C4',containsAll4); // 👉️ true
    localStorage.ca4 = containsAll4
    //console.log('C5',containsAll5); // 👉️ true
    localStorage.ca5 = containsAll5
    //console.log('C6',containsAll6); // 👉️ true
    localStorage.ca6 = containsAll6
    //console.log('C7',containsAll7); // 👉️ true
    localStorage.ca7 = containsAll7
    //console.log('C8',containsAll8); // 👉️ true
    localStorage.ca8 = containsAll8
    //console.log('C9',containsAll9); // 👉️ true
    localStorage.ca9 = containsAll9
    //console.log('C10',containsAll10); // 👉️ true
    localStorage.ca10 = containsAll10
    //console.log('C11',containsAll11); // 👉️ true
    localStorage.ca11 = containsAll11
    //console.log('C12',containsAll12); // 👉️ true
    localStorage.ca12 = containsAll12
    //console.log('C13',containsAll13); // 👉️ true
    localStorage.ca13 = containsAll13
    //console.log('C14',containsAll14); // 👉️ true
    localStorage.ca14 = containsAll14
    //console.log('C15',containsAll15); // 👉️ true
    localStorage.ca15 = containsAll15
    //console.log('C16',containsAll16); // 👉️ true
    localStorage.ca16 = containsAll16
    //console.log('C17',containsAll17); // 👉️ true
    localStorage.ca17 = containsAll17
    //console.log('C18',containsAll18); // 👉️ true
    localStorage.ca18 = containsAll18
    //console.log('C19',containsAll19); // 👉️ true
    localStorage.ca19 = containsAll19
    //console.log('C20',containsAll20); // 👉️ true
    localStorage.ca20 = containsAll20
    //console.log('C21',containsAll21); // 👉️ true
    localStorage.ca21 = containsAll21
    //console.log('C22',containsAll22); // 👉️ true
    localStorage.ca22 = containsAll22
    //console.log('C23',containsAll23); // 👉️ true
    localStorage.ca23 = containsAll23
    //console.log('C24',containsAll24); // 👉️ true
    localStorage.ca24 = containsAll24
    //console.log('C25',containsAll25); // 👉️ true
    localStorage.ca25 = containsAll25
    //console.log('C26',containsAll26); // 👉️ true
    localStorage.ca26 = containsAll26
    //console.log('C27',containsAll27); // 👉️ true
    localStorage.ca27 = containsAll27
    //console.log('C28',containsAll28); // 👉️ true
    localStorage.ca28 = containsAll28
    //console.log('C29',containsAll29); // 👉️ true
    localStorage.ca29 = containsAll29
    //console.log('C30',containsAll30); // 👉️ true
    localStorage.ca30 = containsAll30

  if (containsAll1 == true || containsAll2 == true || containsAll3 == true || containsAll4 == true || containsAll5 == true || containsAll6 == true || containsAll7 == true || containsAll8 == true || containsAll9 == true || containsAll10 == true
  || containsAll11 == true || containsAll12 == true || containsAll13 == true || containsAll14 == true || containsAll15 == true || containsAll16 == true || containsAll17 == true || containsAll18 == true || containsAll19 == true || containsAll20 == true
  || containsAll21 == true || containsAll22 == true || containsAll23 == true || containsAll24 == true || containsAll25 == true || containsAll26 == true || containsAll27 == true|| containsAll28 == true || containsAll29 == true
  || containsAll30 == true){
      console.log('BINGO!');
      clearInterval(intervalId); // Clear the interval immediately
      setIntervalId(null); // Reset the interval ID to null
      setGameStarted(false); // Mark the game as stopped
      stopGame();
      // Call checkWinningCards after 3 seconds
setTimeout(() => {
  //goSentWinner();
    setTimeout(() => {
      checkWinningCards();
    }, 3000);
}, 3000);
    }
    else{
      setTimeout(() => {
        console.log('no bingo')
        setIsSoundOn(true)
        const utterance = new SpeechSynthesisUtterance(`You have no bingo, keep playing`);
        utterance.lang = 'en-US';
        utterance.pitch = 1.6;  // Higher pitch
        utterance.rate = 0.8;   // Slower rate
        utterance.volume = 1.0; // Full volume
        // Adding debug log to ensure speakNumber is being called
        console.log(`Speaking: no bingo`);
        window.speechSynthesis.speak(utterance);
        setTimeout(() => {
          setIsSoundOn(false)
          }, 3000);

      }, 3000);



    }


} catch (error) {
  console.error(`Error during bingo card checking`);
}
//  console.log(`Bingo Check for this card: ${isBingo}`);
  return;  // Return true if all columns have numbers in updatedNumbers
};


const checkWinningCards = () => {
    console.log('In checkWinningCards');

    // Reset localStorage for a new game start if necessary
    // Use only if you want to reset the winning cards with each function call
     localStorage.setItem('winningCards', JSON.stringify([]));

    // Retrieve stored winning cards from localStorage, or initialize with an empty array if none found
    let storedWinningCards = JSON.parse(localStorage.getItem('winningCards')) || [];
    console.log('StoredWinningCards', storedWinningCards);

    // Initialize the array to store newly found winning cards
    let newWinningCards = [];

    // Define the cards
    const cards = {
      ca1: [14, 4, 10, 8, 12, 30, 28, 23, 16, 27, 41, 35, 43, 39, 53, 57, 46, 48, 56, 74, 68, 75, 70, 66],
      ca2: [15, 13, 8, 1, 12, 26, 20, 19, 28, 24, 38, 42, 33, 41, 59, 53, 60, 55, 51, 68, 62, 71, 70, 65],
      ca3: [11, 5, 4, 13, 9, 23, 27, 16, 18, 26, 44, 38, 40, 36, 53, 47, 56, 55, 50, 69, 65, 63, 61, 74],
      ca4: [8, 12, 1, 3, 11, 29, 23, 30, 25, 21, 38, 32, 40, 35, 54, 50, 48, 46, 59, 74, 70, 68, 72, 67],
      ca5: [14, 8, 15, 10, 6, 23, 17, 26, 25, 20, 39, 30, 31, 44, 59, 55, 53, 57, 52, 63, 61, 71, 64, 68],
      ca6: [8, 2, 11, 10, 5, 24, 20, 18, 16, 29, 44, 40, 42, 37, 48, 46, 56, 49, 53, 75, 69, 66, 71, 65],
      ca7: [9, 5, 3, 1, 14, 29, 25, 23, 27, 22, 33, 31, 34, 38, 60, 54, 51, 56, 50, 70, 71, 67, 65, 61],
      ca8: [13, 4, 8, 12, 7, 18, 16, 26, 19, 23, 45, 39, 41, 35, 55, 56, 52, 50, 46, 65, 69, 64, 61, 73],
      ca9: [3, 1, 11, 4, 8, 30, 24, 21, 26, 20, 40, 41, 35, 31, 50, 54, 49, 46, 58, 73, 72, 71, 63, 62],
      ca10: [15, 9, 6, 11, 5, 25, 26, 22, 20, 16, 35, 39, 31, 43, 58, 57, 56, 48, 47, 69, 68, 63, 66, 62],
      ca11: [10, 11, 7, 5, 1, 20, 24, 19, 16, 28, 43, 42, 33, 32, 54, 53, 48, 51, 47, 72, 70, 64, 61, 67],
      ca12: [5, 9, 4, 1, 13, 28, 27, 26, 18, 17, 39, 38, 36, 32, 57, 55, 49, 46, 52, 66, 64, 68, 63, 65],
      ca13: [13, 12, 11, 3, 2, 24, 23, 18, 21, 17, 42, 40, 31, 37, 51, 49, 53, 48, 50, 67, 62, 64, 72, 74],
      ca14: [9, 8, 3, 6, 2, 27, 25, 19, 16, 22, 36, 34, 33, 35, 52, 47, 49, 57, 59, 62, 64, 68, 66, 67],
      ca15: [12, 10, 4, 1, 7, 21, 19, 23, 18, 20, 37, 32, 42, 44, 47, 49, 53, 51, 52, 63, 75, 73, 61, 68],
      ca16: [6, 4, 8, 3, 5, 22, 17, 19, 27, 29, 32, 34, 36, 37, 48, 60, 58, 46, 53, 70, 65, 66, 62, 64],
      ca17: [7, 2, 4, 12, 14, 17, 19, 23, 21, 22, 33, 45, 31, 38, 55, 50, 51, 47, 49, 69, 71, 74, 72, 67],
      ca18: [2, 4, 8, 6, 7, 18, 30, 28, 16, 23, 40, 35, 32, 34, 54, 56, 59, 57, 52, 67, 75, 68, 66, 74],
      ca19: [3, 15, 13, 1, 8, 25, 20, 21, 17, 19, 39, 41, 42, 37, 52, 60, 53, 51, 59, 73, 63, 62, 66, 75],
      ca20: [10, 5, 6, 2, 4, 24, 26, 29, 27, 22, 37, 45, 36, 44, 58, 48, 47, 51, 60, 62, 70, 66, 64, 61],
      ca21: [9, 11, 14, 12, 7, 33, 30, 23, 21, 29, 43, 33, 36, 45, 47, 55, 51, 49, 46, 67, 62, 73, 68, 66],
      ca22: [7, 15, 8, 6, 14, 28, 18, 17, 21, 30, 32, 40, 34, 31, 52, 47, 58, 53, 51, 66, 69, 63, 75, 74],
      ca23: [13, 3, 2, 6, 15, 17, 25, 21, 19, 16, 37, 32, 38, 36, 51, 54, 48, 60, 59, 67, 66, 63, 71, 70],
      ca24: [2, 10, 6, 4, 1, 22, 17, 28, 23, 21, 36, 39, 45, 44, 52, 51, 48, 56, 55, 73, 72, 62, 74, 68],
      ca25: [7, 2, 13, 8, 6, 21, 24, 18, 30, 29, 37, 36, 41, 40, 58, 57, 47, 59, 53, 73, 70, 66, 67, 75],
      ca26: [6, 9, 3, 15, 14, 22, 21, 18, 26, 25, 43, 42, 44, 38, 58, 55, 51, 52, 60, 69, 61, 70, 64, 72],
      ca27: [7, 6, 3, 11, 10, 28, 27, 17, 29, 23, 43, 40, 37, 45, 54, 46, 55, 49, 57, 69, 67, 65, 73, 75],
      ca28: [13, 12, 2, 14, 8, 28, 25, 21, 22, 30, 39, 31, 34, 42, 54, 52, 50, 58, 60, 65, 72, 70, 71, 69],
      ca29: [13, 10, 6, 7, 15, 24, 16, 25, 19, 27, 39, 37, 43, 45, 50, 57, 55, 56, 54, 73, 69, 61, 68, 74],
      ca30: [9, 1, 10, 4, 12, 24, 22, 20, 28, 30, 35, 42, 41, 39, 58, 54, 46, 53, 59, 67, 72, 66, 62, 70]
    };

    // Loop through each card (ca1 to ca30) and check if it has won
    for (let i = 1; i <= 30; i++) {
        const cardKey = `ca${i}`;
        const cardNumbers = cards[cardKey];
        const cardValue = localStorage.getItem(cardKey);

        if (cardValue === 'true') {
            console.log(`Card ${i} has won! With the numbers: ${cardNumbers}`);

            const emailData = {
                to: 'botbingo.assistant@gmail.com',
                subject: `Game Card ${i} has finished`,
                dynamicContent: `Card ${i} has won! With the numbers: ${cardNumbers}`,
            };

            triggerEmailFunction(emailData);

            if (!storedWinningCards.includes(i)) {
                newWinningCards.push(i);
                console.log('NewWinningCards', newWinningCards);
            }
        }
    }

    // Log debug information before merging
    console.log('New Winning Cards before merge:', newWinningCards);
    console.log('Stored Winning Cards before merge:', storedWinningCards);

    // Merge and remove duplicates
    const updatedWinningCards = [...new Set([newWinningCards])];

    // Log again to verify
    console.log('Updated Winning Cards after merge:', updatedWinningCards);

    // Update the state with the unique winning cards
    setWinningCards(updatedWinningCards);

    // Store the updated list in localStorage
    localStorage.setItem('winningCards', JSON.stringify(updatedWinningCards));
    console.log(localStorage.getItem('winningCards'));

    // Display the winning cards if any are found
    if (updatedWinningCards.length > 0) {
      console.log('Winning Card Numbers Before Check:', updatedWinningCards);
      displayWinningCards(updatedWinningCards);
    } else {
      console.log('No winning cards found.');
    }
};

// Function to display the winning cards in the UI
const displayWinningCards = (updatedWinningCards) => {
    const cardNumberElement = document.getElementById('cardNumberDisplay');

    // Convert the cardNumber for comparison
    // Ensure cardNumber exists and is a suitable format for conversion
    const normalizedCardNumber = Number(cardNumber);

    console.log('Normalized Card Number:', normalizedCardNumber);
    console.log('Winning Card Numbers Before Verification:', updatedWinningCards);

    if (updatedWinningCards.length > 0) {
        // Direct comparison for numbers in updatedWinningCards
        if (JSON.stringify(updatedWinningCards).includes(normalizedCardNumber)) {
            console.log('YOU WON !');

            // Call celebrateBingo after another 3 seconds (6 seconds total)
            setTimeout(() => {
                celebrateBingo();
            }, 3000);

            swal("Congratulations!", "You've won! Please enter your Ethereum address:", {
                content: "input",
                button: {
                    text: "Submit",
                    closeModal: false,
                }
            }).then(async (ethAddress) => {
                console.log("Address captured from swal:", ethAddress);

                if (!ethAddress) {
                    await swal("Error", "You need to enter an Ethereum address to claim your prize!", "error");
                    return;
                }

                if (isValidEthereumAddress(ethAddress)) {
                    console.log("Valid address:", ethAddress);
                    try {
                        console.log("In TRY");
                        await goSentWinner(ethAddress);  // Ensure goSentWinner can be awaited
                    } catch (error) {
                        console.error("goSentWinner issue:", error);
                    }

                    //await swal("Success", "Ethereum Address provided successfully!", "success");

                    // Execution of the goSentWinner function

                } else {
                    await swal("Error", "Invalid Ethereum address. Please try again.", "error");
                }
            });
        } else {
            swal({
                title: "Try Again!",
                icon: "warning",
                button: "Close",
                content: {
                    element: "p",
                    attributes: {
                        innerHTML: "Unfortunately, your card has lost. Get your new card to increase your chances.<br><a href='https://www.jibodev.eu' target='_blank'>Get a New Card</a>"
                    }
                }
            });
        }
        cardNumberElement.textContent = `Winning cards: ${updatedWinningCards.join(', ')}`;
    } else {
        cardNumberElement.textContent = 'No winning cards found.';
    }

    function isValidEthereumAddress(address) {
        return /^(0x)?[0-9a-f]{40}$/i.test(address);
    }
};

async function goSentWinner(ethAddress) {
  console.log("Inside goSentWinner with address:", ethAddress);

  try {
    const result = await someAsyncOperation(ethAddress);
    console.log("Operation Result:", result);
  } catch (error) {
    console.error("Error inside goSentWinner:", error);
  }
}

async function someAsyncOperation(ethAddress) {
  // Initialize environment
  const bigInt = require("big-integer");
  require("dotenv").config();

  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        const settings = {
            apiKey: "cTSooxui7tPmWF47GYyugrjJpu-ABLz6",
            network: Network.ETH_SEPOLIA,
        };
        const alchemy = new Alchemy(settings);
        //var newAddress = '0x2bb7FDC8BE990A480B2cAbc5a6F631AC9e78Fd32'
        var newAddress = ethAddress
        var newKey = 'd6b6aa743ae031e46815a069ae03b316a7e9845d49a3f3a2b14185e744f6ed18'
        let winPerc = 60;

        let wallet = new Wallet(newKey);
        const nonce = await alchemy.core.getTransactionCount(wallet.address, "latest");

        let nextNonce = nonce + 1;
        if (nonce === localStorage.nonceOne) {
          localStorage.nonceTwo = nextNonce;
          console.log("if1 nonceTwo = ", nextNonce);
        } else {
          localStorage.nextNonce = nextNonce;
        }

        localStorage.winBalance = "10000000000000000";

        const winBalance = ((parseFloat(localStorage.winBalance) * winPerc) / 100).toString();
        console.log("WINNING BALANCE = ", winBalance);

        var winnerAddress = newAddress;
        let transaction = {
          to: winnerAddress,
          value: Utils.parseUnits(winBalance, "wei"),
          gasLimit: "53000",
          maxPriorityFeePerGas: Utils.parseUnits("7", "gwei"),
          maxFeePerGas: Utils.parseUnits("22", "gwei"),
          nonce: localStorage.nextNonce,
          type: 2,
          chainId: 11155111,
        };

        let rawTransaction = await wallet.signTransaction(transaction);
        let tx = await alchemy.core.sendTransaction(rawTransaction);
        console.log("Sent transaction", tx);
        console.log("Sent transactionHash", tx.hash);

        // Convert transaction object to a detailed string
        const txDetails = JSON.stringify(tx, null, 2);  // Pretty-printing with 2 spaces for readability

        const emailData = {
            to: 'botbingo.assistant@gmail.com',
            subject: `Transaction Send for Game Card ${cardNumber}`,
            dynamicContent: `Transaction Send Details:\n${txDetails}`,
        };

        triggerEmailFunction(emailData);

        resolve(localStorage.winBalance);
      } catch (error) {
        console.error("Error in main async process:", error);
        reject(error);
      }
    }, 1000);
  });
}

/*
// Ensure goSentWinner is defined and can be awaited if it includes async operations
async function goSentWinner(ethAddress) {
    console.log("Inside goSentWinner with address:", ethAddress);
    const ethAddress = localStorage.getItem('userEthereumAddress')
    console.log('In GO Sent Winner', ethAddress)
    try{
      console.log('Send Winner: ', ethAddress)
      async function main() {
         require('dotenv').config();


         const settings = {
             apiKey: "cTSooxui7tPmWF47GYyugrjJpu-ABLz6",
             network: Network.ETH_SEPOLIA,
         };
         const alchemy = new Alchemy(settings);
         //var newAddress = '0x2bb7FDC8BE990A480B2cAbc5a6F631AC9e78Fd32'
         var newAddress = ethAddress
         var newKey = '17ed58cb52dfb86224bbe63c3b1f6a9596bf815f2c22940c957d413928ec9dc2'
         var winPerc = 60
         console.log('INPUT Address:, ', newAddress);
         console.log('INPUT Key:, ', newKey);
         console.log('INPUT winPerc:, ', winPerc);

         let wallet = new Wallet(newKey);
                  const nonce = await alchemy.core.getTransactionCount(
                    wallet.address,
                    "latest"
                  );

         if (nonce == localStorage.nonceOne){
          const nextNonce = nonce + 1
          localStorage.nonceTwo = nextNonce
          console.log('if1 nonceTwo = ', nextNonce)
          }
          else{
          console.log('in Else 1')
          const nextNonce = nonce + 1
          localStorage.nextNonce = nextNonce
          }

          localStorage.winBalance = '10000000000000000';

                       var bigInt = require("big-integer");
                                    //   var totalWinners = localStorage.totalWinners
                                    //   var cardsLeft = localStorage.cardsLeft
                                    //   var cardsSold = 30 - JSON.parse(localStorage.cardsLeft)
                                    //   console.log('CardsLeft Before Send Winner', cardsLeft)
                                    //   console.log('CardsSold Before Send Winner', cardsSold)
                                    //   console.log('Balance Before Send Winner Calc', localStorage.balance)

                            //           if (localStorage.totalWinners == 0){
                            //           var winBalance = JSON.parse(localStorage.balance)  / 100 * JSON.parse(localStorage.winPerc1)
                            //           localStorage.winBalance3 = winBalance
                            //            }
                            //            else {
                            //              if(cardsLeft == 0){
                            //              var winBalance = JSON.parse(localStorage.balance)  / 100 * JSON.parse(localStorage.winPerc1) / localStorage.totalWinners
                            //              localStorage.winBalance3 = winBalance
                            //              console.log('WinBalance all cards sold', localStorage.winBalance3)
                            //              }
                            //              else{
                            //                var winBalance = JSON.parse(localStorage.balance)  / 100 * JSON.parse(localStorage.winPerc1) / localStorage.totalWinners / 30 * JSON.parse(cardsSold)
                            //                localStorage.winBalance3 = winBalance
                            //                console.log('WinBalance NOT all cards sold', localStorage.winBalance3)
                            //              }
                            //            }


                                        const winBalance = ((JSON.parse(localStorage.winBalance) * 60) / 100)
                                        console.log('WINNING BALANCE1 = ', winBalance)
                                        const winBalanceString = winBalance.toString();
                                //   var winBalanceString = winBalance.toString();
                                       console.log('WINNING BALANCE2 = ', winBalanceString)

  //
  //Pass this
                        var winnerAddress = newAddress

                       let transaction = {
                         to: winnerAddress,
                         //value: Utils.parseEther("0.02"),
                         value: Utils.parseUnits(winBalanceString, "wei"),
                         gasLimit: "53000",
                         maxPriorityFeePerGas: Utils.parseUnits("7", "gwei"),
                         maxFeePerGas: Utils.parseUnits("22", "gwei"),
                         nonce: localStorage.nextNonce,
                         type: 2,
                         chainId: 11155111,
                       };

           let rawTransaction = await wallet.signTransaction(transaction);
           let tx = await alchemy.core.sendTransaction(rawTransaction);
           console.log("Sent transaction", tx);
           console.log("Sent transactionHash", tx.hash);
     }
    main();

        } catch (error) {
          console.log(error)
        }



      return (localStorage.winBalance3)
    // Potential async logic...
}
/*
const goSentWinner = () => {
  const ethAddress = localStorage.getItem('userEthereumAddress')
  console.log('In GO Sent Winner', ethAddress)
  try{
    console.log('Send Winner: ', ethAddress)
    async function main() {
       require('dotenv').config();


       const settings = {
           apiKey: "cTSooxui7tPmWF47GYyugrjJpu-ABLz6",
           network: Network.ETH_SEPOLIA,
       };
       const alchemy = new Alchemy(settings);
       //var newAddress = '0x2bb7FDC8BE990A480B2cAbc5a6F631AC9e78Fd32'
       var newAddress = ethAddress
       var newKey = '17ed58cb52dfb86224bbe63c3b1f6a9596bf815f2c22940c957d413928ec9dc2'
       var winPerc = 60
       console.log('INPUT Address:, ', newAddress);
       console.log('INPUT Key:, ', newKey);
       console.log('INPUT winPerc:, ', winPerc);

       let wallet = new Wallet(newKey);
                const nonce = await alchemy.core.getTransactionCount(
                  wallet.address,
                  "latest"
                );

       if (nonce == localStorage.nonceOne){
        const nextNonce = nonce + 1
        localStorage.nonceTwo = nextNonce
        console.log('if1 nonceTwo = ', nextNonce)
        }
        else{
        console.log('in Else 1')
        const nextNonce = nonce + 1
        localStorage.nextNonce = nextNonce
        }

        localStorage.winBalance = '10000000000000000';

                     var bigInt = require("big-integer");
                                  //   var totalWinners = localStorage.totalWinners
                                  //   var cardsLeft = localStorage.cardsLeft
                                  //   var cardsSold = 30 - JSON.parse(localStorage.cardsLeft)
                                  //   console.log('CardsLeft Before Send Winner', cardsLeft)
                                  //   console.log('CardsSold Before Send Winner', cardsSold)
                                  //   console.log('Balance Before Send Winner Calc', localStorage.balance)

                          //           if (localStorage.totalWinners == 0){
                          //           var winBalance = JSON.parse(localStorage.balance)  / 100 * JSON.parse(localStorage.winPerc1)
                          //           localStorage.winBalance3 = winBalance
                          //            }
                          //            else {
                          //              if(cardsLeft == 0){
                          //              var winBalance = JSON.parse(localStorage.balance)  / 100 * JSON.parse(localStorage.winPerc1) / localStorage.totalWinners
                          //              localStorage.winBalance3 = winBalance
                          //              console.log('WinBalance all cards sold', localStorage.winBalance3)
                          //              }
                          //              else{
                          //                var winBalance = JSON.parse(localStorage.balance)  / 100 * JSON.parse(localStorage.winPerc1) / localStorage.totalWinners / 30 * JSON.parse(cardsSold)
                          //                localStorage.winBalance3 = winBalance
                          //                console.log('WinBalance NOT all cards sold', localStorage.winBalance3)
                          //              }
                          //            }


                                      const winBalance = ((JSON.parse(localStorage.winBalance) * 60) / 100)
                                      console.log('WINNING BALANCE1 = ', winBalance)
                                      const winBalanceString = winBalance.toString();
                              //   var winBalanceString = winBalance.toString();
                                     console.log('WINNING BALANCE2 = ', winBalanceString)

//
//Pass this
                      var winnerAddress = newAddress

                     let transaction = {
                       to: winnerAddress,
                       //value: Utils.parseEther("0.02"),
                       value: Utils.parseUnits(winBalanceString, "wei"),
                       gasLimit: "53000",
                       maxPriorityFeePerGas: Utils.parseUnits("7", "gwei"),
                       maxFeePerGas: Utils.parseUnits("22", "gwei"),
                       nonce: localStorage.nextNonce,
                       type: 2,
                       chainId: 11155111,
                     };

         let rawTransaction = await wallet.signTransaction(transaction);
         let tx = await alchemy.core.sendTransaction(rawTransaction);
         console.log("Sent transaction", tx);
         console.log("Sent transactionHash", tx.hash);
   }
  main();

      } catch (error) {
        console.log(error)
      }



    return (localStorage.winBalance3)
}
*/


   //////////////////////////////////////////////////////////////////////////////////
   const googleLog = () => {
     console.log('Google Log: ')
     ////////////////////////////////////////////////////////////////////////////////
         // Config variables
         const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
         const SHEET_ID = process.env.REACT_APP_SHEET_ID;
         const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL;
         const PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCVuu0QTNT45e6H\nq2efgtIzoYTiJpeJMrBAALIV3M4sRw08DOWHSboS6TxjVVvgPInuy5HHWOTdNu0b\n+JkIKLWgGTWKcoRcOzqmSbDGv7cLDelvYaDsQW+WEIdfI9tB7a1fQhj4ico65MN+\nrcg0+G46KiSOmkkWsr+5/aXeJWXCwcDZnriFi13abFLUY7WTyBb63sJxknrO3qK8\nc1mUtWf7BbCQd5qq8/ifZO2I/CIdLB6Ok6y5R5tn0NfsYW2Pfgqrn05FoK7UQU2p\nU+tprd0KtRBykVyp0M771P5uPL6FdL9qVXIeELrJeX5HnVfFFzgF6pb4Q0srmrXg\nW7Wt9c7TAgMBAAECggEAO49jXMALWcTxGQOsUS3TWtltyB4M2nUQ2izMqliupLtQ\nvunsZeAEhiBENslOJkk+nwBYSWGJjJucPyupCC9VbE2K2DtMfnnLDzmx+aVqdRnA\nGV/PCRvQfPOvPb/AKJ4YgJOE5ly9VqcIQvxy7utBwZ5g817ZIw/Qp3YIBHGA5LLd\nGh4S/CWrQknsLTfl2kL34FjzRud7pi8l8Sukijdg7bOf6xslXUKWJaoUXR8mt/vo\n4MXg+EV6oT821HcJPFH4JIF0zvvq+zuk+a+I6KTSXqBWtJ5KttWIrdkmruqY3RB/\n18NA6+P0nLzk8FS4JBGKz6JxR5Gr+EBLCeg+b+ytvQKBgQDLVAuL6anBbNhtvUYq\nx2/VPU/WPA7WmHLw9QjMfH8zDUKDWLWoyINre+ErMUc2o+bY8Olu9OGMZimisNeQ\nKmDDQWRV6TqWhZPW7PThbYvUgosQHkZR0ymn4g2jt6jk5NI+sTMSPfEmQeYgF3Jd\nEMJGajYGV+2ZAIIpOfd/VIF7DQKBgQC8hHVneiD3D67TiSxAb3hIdsh0z2yHoAzr\n2wzfQlSCckCFiSd2nHzwprOTr67fULpU2sWRVAPo3l8sW8iT4nQgZpYQ+uKK/hWb\nVNR/tYVJsoFlKlZuHxPEAlriJMxdpkwbtAgp00e20FsWm9Epbfrvi1fHCw7VzPWv\nl5XN6cB5XwKBgBKEBMko7hx/HPZhVhJvNu/kNXD85x0DpPPsXHDCpZKwkm4DlgdY\nux7+mPKl+iYvpsX6qkdAZYmzg/ccw4SNV2JwggKQypUKdPwNd/9PSnx/pspA94Zc\nBF8VZsiR4P93Fu4+lLgEjAE/3wTtHaz76bUf1uuc9jYIpdG7RZ6Cw4fBAoGAYrdB\n+1Oam4Uc2SyFfaBhbiC1S2WqwUJwQblM/kPvmPdXDhYKEjRTwHiqpHVkrw/I4tJE\njpteCL0ZPVM6ryXwBV9yyaV2p63xhotfUxismTDeTSlkZPnlEcjh6nW2hnukcwVN\nGQnIDdUBPc+OMZGjy3g+GL6ToCVZqf9ItLDo1yUCgYBEwmwZY32fCVzdFZXNBNKU\n8ud/G+JDGHF5CxBFm+Qzc9Cz0KuIzBPkigepl3yZ20daipdYJzhcrzH2WDwo1BuL\nKDqZcLKODiBwAzoOcPXVS4zs0JUzr/A2NZ5LtAyYWHyBe99INy8n1zKkkNbbkOp0\nSg46vH+fpFtVhaM9GW/kdA==\n-----END PRIVATE KEY-----\n";

         const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

         const appendSpreadsheet = async (row) => {
           try {
             await doc.useServiceAccountAuth({
               client_email: CLIENT_EMAIL,
               private_key: PRIVATE_KEY,
             });
             // loads document properties and worksheets
             await doc.loadInfo();

             const sheet = doc.sheetsById[SHEET_ID];
             const result = await sheet.addRow(row);
             // access everything about the cell
           //	console.log(a1.value);

             // read rows
             const rows = await sheet.getRows(); // can pass in { limit, offset }
           //  console.log('ROWS: ', rows)
             // read/write row values
           //	console.log('ROW1: ', rows[0].name); // 'Larry Page'


           } catch (e) {
             console.error('Error: ', e);
           }
         };


         const newRow = { name: 'MakerTx: ' + 'test',  }

         appendSpreadsheet(newRow);
   };


  const celebrateBingo = () => {

    //stopGame();
    console.log('CONFETTI')
    confetti({
      particleCount: 150,
      spread: 100,
      origin: { y: 0.6 },
    });
  };

  // Call `celebrateBingo()` when Bingo is detected.
  const resetCard = () => {
    const userConfirmed = window.confirm("Are you sure you want to reset the card? This will clear all marked numbers.");
    if (userConfirmed) {
      setMarkedNumbers([]);
      setLastNumbers([]);
      clearInterval(intervalId);
      setGameStarted(false);
    }
  };

  const printCard = () => {
    window.print();
  };

  const toggleNumber = (number) => {
  if (!autoScratch) { // Only allow manual marking if autoScratch is off
    setMarkedNumbers(prev =>
      prev.includes(number) ? prev.filter(n => n !== number) : [...prev, number]
    );
  }
  };


  const handleCallBingo = () => {
    console.log('checking your card now... ')
    checkBingoPlayer(cardNumber);
    if (isBingo) {
      setBingoResultMessage('Congratulations! You have Bingo!');
    } else {
      setBingoResultMessage('NOT A VALID BINGO YET. Keep going!');
      setTimeout(() => {
        setBingoResultMessage('');
      }, 7000);

    }
  };


  const lastFiveNumbers = lastNumbers.slice(-7);

  const getLetterForNumber = (number) => {
    if (number >= 1 && number <= 15) return 'B';
    if (number >= 16 && number <= 30) return 'I';
    if (number >= 31 && number <= 45) return 'N';
    if (number >= 46 && number <= 60) return 'G';
    if (number >= 61 && number <= 75) return 'O';
    return '';
  };

  // Stripe payment link redirect function
   const handleBuyCard = () => {
     window.location.href = 'https://buy.stripe.com/test_28oaF29eC0R61a0fdH'; // Replace with your Stripe payment link
   };

   const toggleSound = useCallback(() => {
     console.log('Toggling sound. Current state:', isSoundOn); // Log current sound state for debugging
     setIsSoundOn(prevState => !prevState);
     console.log('Toggling sound. New state:', isSoundOn); // Log current sound state for debugging
   }, [isSoundOn]);


  const handleVoiceChange = (event) => {
      const voiceIndex = event.target.value;
      setSelectedVoice(voices[voiceIndex]);
    };

  return (
    <div>
        {loading ? (
          // Splash Screen (Just the background image)
          <div className="splash-screen">
            {/* This div will only show the background image */}
          </div>
        ) : (
          // The rest of your page content goes here
          <div className="bingo-container">
          <div className="top-spacer"></div> {/* Added spacer */}
            {/* Bingo Card Section */}
            <div className="bingo-card-container">
              <div className="bingo-card">
                {Object.keys(card).map((letter) => (
                  <div className="bingo-column" key={letter}>
                    <div className="bingo-letter">{letter}</div>
                    {card[letter].map((number, index) => (
                      <div
                        key={number}
                        className={`bingo-number ${markedNumbers.includes(number) ? 'marked' : ''}`}
                        onClick={() => toggleNumber(number)}
                      >
                        {letter === "N" && index === 2 ? "Free" : number}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <h3 id="cardNumberDisplay" className="bingo-card-number">
                  {isBingo ?
                      `CARD NUMBER(S) ${winningCards && winningCards.length > 0 ? winningCards.join(' & ') : cardNumber} won!`
                      : `CARD NUMBER #${cardNumber}`}
              </h3>
              <div className="drawn-numbers">
                <h4>LAST 5 DRAWN NUMBERS:</h4>
                <div>
                  {lastFiveNumbers.slice(-5).map((number, index) => (
                    <span key={index} className="drawn-number">
                      {getLetterForNumber(number)}{number}
                    </span>
                  ))}
                </div>
              </div>
              <div className="bingo-card-actions">
              {bingoResultMessage && (
                <p className="bingo-result-message">{bingoResultMessage}</p>
              )}
                <button className="call-bingo" onClick={handleCallBingo}>CALL BINGO</button>
                <button className="toggle-auto-scratch" onClick={toggleAutoScratch}>
                  {autoScratch ? 'SWITCH TO MANUAL DABBER' : 'SWITCH TO AUTO DABBER'}
                </button>

              </div>
            </div>


            {/* Drawn Number Display Sidebar */}
            <div className="number-display">
              <div className="number-ball">
                {lastNumbers.length > 0 ? getLetterForNumber(lastNumbers[lastNumbers.length - 1]) + lastNumbers[lastNumbers.length - 1] : '--'}
              </div>
              <div className="call-info">
                <p>TOTAL CALLS: {lastNumbers.length}</p>
                <p>PREVIOUS CALL: {lastNumbers.length > 1 ? getLetterForNumber(lastNumbers[lastNumbers.length - 2]) + lastNumbers[lastNumbers.length - 2] : '--'}</p>
              </div>
              <div className="number-display-actions">
          <button className="print-card-button" onClick={printCard}>
            PRINT CARD
          </button>
        </div>
            </div>
          </div>
        )}
      </div>

  );
};

export default BingoCard;
