import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BingoCard from './BingoCard';
import { bingoCards } from '../cards';

const CardPage = () => {
  const { cardNumber } = useParams();
  const card = bingoCards[cardNumber];

  const [lastNumbers, setLastNumbers] = useState([]);
  const [gamePaused, setGamePaused] = useState(true); // Game is paused initially
  const [board, setBoard] = useState([]);
  const [intervalId, setIntervalId] = useState(null);




  // Function to start the game
  const startGame = () => {
    setGamePaused(false);
    setLastNumbers([]); // Reset last drawn numbers when the game starts
    drawNumber(); // Immediately draw the first number

    const id = setInterval(() => {
      drawNumber();
    }, 15000); // Draw a new number every 15 seconds

    setIntervalId(id); // Store the interval ID so we can clear it later
  };

  // Function to stop the game
  const stopGame = () => {
    setGamePaused(true);
    if (intervalId) {
      clearInterval(intervalId); // Stop the number drawing
    }
  };

  // Function to reset the card state (optional)
  const resetCardState = () => {
    if (card && typeof card === 'object') {
      const resetBoard = Object.keys(card).map(column =>
        card[column].map(number => ({ ...number, called: false, active: false }))
      );
      setBoard(resetBoard);
    } else {
      console.error("Card is not valid or does not exist", card);
      setBoard([]); // Set an empty array or some default value to avoid further issues
    }
  };

  // Function to draw a random number
  const drawNumber = () => {
    if (lastNumbers.length >= 75) return; // Stop drawing if all numbers have been drawn

    let number;
    do {
      number = Math.floor(Math.random() * 75) + 1; // Generate random number between 1 and 75
    } while (lastNumbers.includes(number)); // Avoid duplicates

    setLastNumbers((prevNumbers) => [...prevNumbers, number]);
  };

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Clean up interval on component unmount
      }
    };
  }, [intervalId]);

  if (!card) {
    return <h2>Card not found</h2>;
  }

  return (
    <div className="card-page-container">
    <div className="mobile-spacer"></div>
      <BingoCard
        card={card}
        lastNumbers={lastNumbers}
        gamePaused={gamePaused}
        drawnNumbers={lastNumbers}
        cardNumber={cardNumber}
      />
    </div>
  );
};

export default CardPage;
